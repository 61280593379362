import { Box, Container, css, Grid, styled, Theme, Typography, useTheme } from "@mui/material";
import React from "react";

const footerClasses = (theme: Theme) => ({
  root: css({
    padding: "15px",
    width: "100%",
    flexShrink: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText
  }),
  link: css({
    color: theme.palette.primary.contrastText
  })
});

const Footer = () => {
  const theme = useTheme();
  const classes = footerClasses(theme);
  return (
    <Box css={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={8}>
          <Grid item md={6} style={{ margin: "0 auto" }}>
            <div style={{ textAlign: "center", margin: "0 auto" }}>
              <Typography>Office Location</Typography>
              <Typography>
                <a
                  href="https://goo.gl/maps/RhC7ibxardiKVa8s5"
                  css={classes.link}
                >
                  112 Olean Road Suite 220, East Aurora, New York 14052
                </a>
              </Typography>
            </div>
          </Grid>
          <Grid item md={6} style={{ margin: "0 auto" }}>
            <div style={{ textAlign: "center" }}>
              <Typography>
                Phone:{" "}
                <a href="tel:7168051072" css={classes.link}>
                  716-805-1072
                </a>
              </Typography>
              <Typography>
                Facsimile:{" "}
                <a href="fax:7168051073" css={classes.link}>
                  716-805-1073
                </a>
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Typography align="center" style={{ paddingTop: "12px" }}>
          {"Copyright © East Aurora Family Practice "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Container>
    </Box>
  );
};

export default Footer;