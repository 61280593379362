import { createTheme, responsiveFontSizes, Theme } from "@mui/material";

const EAFPTheme = responsiveFontSizes(
  createTheme({
    typography: {
      h1: {
        fontSize: "4rem",
        fontWeight: 300,
      },
      h2: {
        fontSize: "2.5rem",
        fontWeight: 200,
      },
      h3: {
        fontSize: "2rem",
        fontWeight: 100,
      },
      h4: {
        fontSize: "1.5rem",
        fontWeight: 100,
      },
      h5: {
        fontSize: "1.25rem",
        fontWeight: 100,
      },
      h6: {
        fontSize: "1rem",
        fontWeight: 100,
      },
    },
    palette: {
      primary: {
        main: "#1976d2",
        contrastText: "#ffffff",
        dark: "#004ba0",
        light: "#63a4ff",
      },
      secondary: {
        main: "#ff7043",
        contrastText: "#000000",
        dark: "#c63f17",
        light: "#ffa270",
      },
    },
  })
);

export default EAFPTheme;
