import { Route, Routes as DomRoutes } from "react-router-dom";
import React, { lazy, Suspense } from "react";

const pc = import("../pages/PatientCenter/PatientCenter");
const LazyPatientCenter = lazy(() => pc);
const pp = import("../pages/PatientPortal/PatientPortal");
const LazyPatientPortal = lazy(() => pp);
const pf = import("../pages/PatientForms/PatientForms");
const LazyPatientForms = lazy(() => pf);
const i = import("../pages/Insurance/Insurance");
const LazyInsurance = lazy(() => i);
const he = import("../pages/HealthEducation/HealthEducation");
const LazyHealthEducation = lazy(() => he);
const providers = import("../pages/Providers/Providers");
const LazyProviders = lazy(() => providers);
const home = import("../pages/Home/Home");
const LazyHome = lazy(() => home);

const TransitionLoading = () => <div></div>;

const RouteSuspense = (props: any) => (
  <Suspense fallback={<TransitionLoading />}>{props.children}</Suspense>
);

const Routes = () => {
  return (
    <DomRoutes>
      <Route path="/patient-center" element={<RouteSuspense>
        <LazyPatientCenter />
      </RouteSuspense>} />
      <Route path="/patient-portal" element={<RouteSuspense>
        <LazyPatientPortal />
      </RouteSuspense>} />
      <Route path="/patient-forms/*" element={<RouteSuspense>
        <LazyPatientForms />
      </RouteSuspense>} />
      <Route path="/insurance" element={<RouteSuspense>
        <LazyInsurance />
      </RouteSuspense>} />
      <Route path="/health-education" element={<RouteSuspense>
        <LazyHealthEducation />
      </RouteSuspense>} />
      <Route path="/providers" element={<RouteSuspense>
        <LazyProviders />
      </RouteSuspense>} />
      <Route path="/" element={<RouteSuspense>
        <LazyHome />
      </RouteSuspense>} />
    </DomRoutes>
  );
};

export default Routes;
