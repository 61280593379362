import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import clsx from "clsx";
import { AppBar, Box, Container, css, Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemText, Theme, Toolbar, Typography, useTheme } from "@mui/material";
import MenuIcon from '@mui/icons-material/Menu';

const paths = [
  {
    to: '/providers',
    name: 'Providers'
  },
  {
    to: '/health-education',
    name: 'Health Education'
  },
  {
    to: '/insurance',
    name: 'Insurance'
  },
  {
    to: '/patient-forms',
    name: 'Patient Forms'
  },
  {
    to: '/patient-portal',
    name: 'Patient Portal'
  },
  {
    href: 'https://pay.balancecollect.com/m/eastaurorafamilypractice',
    name: 'Pay Bill'
  }
];

const baseLinkStyle = (theme: Theme) => css`
  color: ${theme.palette.primary.contrastText};
  text-decoration: none;
  padding: 10px 5px 10px 5px;
  margin: 0 4px;
  border-radius: 5px;
  font-sze: 1em;
  &:hover {
    background-color: ${theme.palette.primary.light};
  }
  &.active {
    background-color: ${theme.palette.primary.dark};
  }
`;

interface INavLink {
  children: string;
  to?: string;
  href?: string;
}

const NavLink = (props: INavLink) => {
  const location = useLocation();
  const theme = useTheme();
  const cssStyle = baseLinkStyle(theme);
  if (props.to) {
    return (
      <Link
        to={props.to}
        className={clsx(
          location.pathname === props.to ? 'active' : ''
        )}
        css={cssStyle}
      >
        {props.children}
      </Link>
    );
  } else if (props.href) {
    return <a href={props.href} target="_blank" css={cssStyle}>{props.children}</a>
  } else {
    return <></>;
  }
};

const DrawerAppBar = (props: { window?: () => Window }) => {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = useState(false);
  const drawerWidth = 240;

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography component={Link} to="/" variant="h6" sx={{ my: 2, textDecoration: 'none', color: 'black', fontSize: '1.2rem', padding: '0.5rem' }}>
        East Aurora Family Practice
      </Typography>
      <Divider />
      <List>
        {paths.map((nav, i) => {
          const textElement = <ListItemText primary={nav.name} />;
          if (nav.to) {
            return <ListItem key={i} disablePadding>
              <ListItemButton component={Link} to={nav.to} sx={{ textAlign: 'center' }}>
                {textElement}
              </ListItemButton>
            </ListItem>;
          } else if (nav.href) {
            return <ListItem key={i} disablePadding>
              <ListItemButton href={nav.href} target="_blank" sx={{ textAlign: 'center' }}>
                {textElement}
              </ListItemButton>
            </ListItem>
          }
        })}
      </List>
    </Box>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav">
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Container maxWidth="lg">
            <Box display="flex">
              <Typography component={Link} to="/" variant="h6" sx={{
                my: 2,
                textDecoration: 'none',
                color: '#FFF',
                fontSize: '1.1rem',
                fontWeight: 500,
                margin: "8px 0px"
              }}>
                East Aurora Family Practice
              </Typography>
              <Box sx={{ flexGrow: 1, justifyContent: 'flex-end', display: { xs: 'none', sm: 'none', md: 'flex' } }}>
                {paths.map((navPath, i) => (
                  <NavLink key={i} to={navPath.to} href={navPath.href}>{navPath.name}</NavLink>
                ))}
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'block', md: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
};

export default DrawerAppBar;