import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import Navigation from "./shared-components/Navigation";
import Routes from "./shared-components/Routes";
import EAFPTheme from "./shared-components/EAFPTheme";
import Footer from "./shared-components/Footer";
import { Container, CssBaseline, StyledEngineProvider,  ThemeProvider} from "@mui/material";


const App = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={EAFPTheme}>
        <Router>
          <CssBaseline />
          <div style={{ flex: "1 0 auto" }}>
            <Navigation />
            <Container
              maxWidth="lg"
              style={{ padding: "75px 15px 50px 15px", flexGrow: 1 }}
            >
              <Routes />
            </Container>
          </div>
          <Footer />
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
